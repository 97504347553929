import React from 'react'

import './sme-day-offer-tnc.scss'

const SmeDayOfferTnc = () => {
    return (
        <>
	    <div className='main-content'>
		    <div className='title'> SME DAY OFFER TERMS & CONDITIONS </div>
            <p className='sub-heading'>DEFINITIONS</p>
            <p>For the purpose of these terms and conditions (“Terms and Conditions”):</p>
            <ul style={{"listStyle":"disc"}}>
                <li>
                    <p><b>“User”</b>shall mean a business entity that has On-Boarded on the SOLV Platform using its legal name and mobile number, and is legally subsisting during the Offer Period;</p>
                </li>
                <li>
                    <p><b>“Eligible User”</b>shall mean a User which satisfies the conditions of this Offer and becomes eligible to receive the discounts as set out in this Offer; </p>
                </li>
                <li>
                    <p><b>“Offer”</b>shall mean the discounts offered to the Eligible Users for buying (i) FMCG, groceries, fruits, vegetables and dairy products (collectively “FMCG and HoReCa Products”), (ii) apparel products and (iii) consumer electronics products in the Bangalore, Hyderabad & Delhi NCR regions during the Offer Period;  </p>
                </li>
                <li>
                    <p><b>“Offer Period”</b>shall mean the period commencing from 21st June 2021 till 30th June 2021 (both days inclusive), which may be amended by SOLV at any time;  </p>
                </li>
                <li>
                    <p><b>“On-Board” or “On-Boarding”</b>means the downloading and installation of the SOLV application by the User, providing relevant Merchant Information to SOLV, successful verification of Merchant Information and registration of User as a seller/buyer on the SOLV Platform by obtaining a unique SOLV identification number;</p>
                </li>
                <li>
                    <p><b>“Primary Terms and Conditions”</b> shall mean the terms and conditions governing the usage of the SOLV platform, as applicable to the Users as separately provided on the SOLV portal and/or application in addition to these Terms and Conditions set out herein. </p>
                </li>
            </ul>
            <p>Any capitalised terms used in these Terms and Conditions, however not defined herein, shall have the same meanings as attributed to such terms in the Primary Terms and Conditions.    </p>
            <p className='sub-heading'>TRANSACTIONAL OFFERS AND CONDITIONS</p>
            <p className='sub-heading'>1. FMCG and HoReCa Products</p>
            <ul className="liparent" style={{"listStyleType":"lower-alpha"}}>
                        <li>
                            <p>
                                <b>Offer #1:</b>During the Offer Period, in the event any User purchases FMCG and HoReCa Products of invoice value INR 1500 and above on a single order, the user shall be eligible to apply two promotional codes, to avail a discount of INR 30 each, on such transaction being undertaken and a subsequent order of invoice value INR 1500 and above. Use Code: SMEDY30
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Offer #2:</b>  During the Offer Period, in the event any User purchases FMCG and HoReCa Products of invoice value INR 5000 and above on a single order, the User shall be eligible to apply two promotional codes, to avail a discount of INR 100 each, on such transaction being undertaken and a subsequent order of invoice value INR 5000 and above. Use Code: SMEDY100
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Offer #3:</b> During the Offer Period, in the event any User purchases FMCG and HoReCa Products of invoice value INR 15,000 and above on a single order, the User shall be eligible to apply two promotional codes, to avail a discount of INR 225 each, on such transaction being undertaken and a subsequent order of invoice value INR 15,000 and above. Use Code: SMEDY225
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Offer #4:</b> During the Offer Period, in the event any User purchases FMCG and HoReCa Products of invoice value INR 30,000 and above on a single order, the User shall be eligible to apply two promotional codes, to avail a discount of INR 500 each, on such transaction being undertaken and a subsequent order of invoice value INR 30,000 and above. Use Code: SMEDY500
                            </p>
                        </li>
            </ul>

            <p className='sub-heading'> 2. Apparel Products</p>
            <ul className="liparent" style={{"listStyleType":"lower-alpha"}}>
                        <li>
                            <p>
                                <b>Offer #1:</b>During the Offer Period, in the event any User purchases apparel products of invoice value INR 5,000 and above on a single order, the user shall be eligible to apply two promotional codes, to avail a discount of INR 175 each, on such transaction being undertaken and a subsequent order of invoice value INR 5,000 and above. Use Code: SMEDY175
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Offer #2:</b> During the Offer Period, in the event any User purchases apparel products of invoice value INR 10,000 and above on a single order, the User shall be eligible to apply two promotional codes, to avail a discount of INR 400 each, on such transaction being undertaken and a subsequent order of invoice value INR 10,000 and above. Use Code: SMEDY400
                            </p>
                        </li>

            </ul>


            <p className='sub-heading'> 3.	Consumer Electronics</p>
            <ul className="liparent" style={{"listStyleType":"lower-alpha"}}>
                        <li>
                            <p>
                                <b>Offer #1:</b>During the Offer Period, in the event any User purchases consumer electronics products of invoice value INR 5,000 and above on a single order, the user shall be eligible to apply two promotional codes, to avail a discount of INR 125 each, on such transaction being undertaken and a subsequent order of invoice value INR 5,000 and above. Use Code: SMEDY125
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Offer #2:</b> During the Offer Period, in the event any User purchases consumer electronics products of invoice value INR 10,000 and above on a single order, the User shall be eligible to apply two promotional codes, to avail a discount of INR 250 each, on such transaction being undertaken and a subsequent order of invoice value INR 10,000 and above. Use Code: SMEDY250
                            </p>
                        </li>

            </ul>

            <p className='sub-heading'>Note: </p>
            <ul style={{"listStyleType":"decimal"}}>
                        <li>
                            <p>
                            	The User will be eligible to use all the codes indicated above only during the Offer Period in respect of 2 (two) separate transactions of order value exceeding the required invoice values on the Platform.
                            </p>
                        </li>
                        <li>
                            <p>
                            	The promotional code must be selected and applied during check-out for the User to avail the discount.
                            </p>
                        </li>
                        <li>
                            <p>
                            	In the event of any newly On-Boarded User, the promotional coupon codes may not immediately get activated and such activation may take up to 24 hours. During such interim period, the new Users are encouraged to contact our sales officers/help desk for receiving the relevant promotional codes to be applied for any transaction.   
                            </p>
                        </li>
            </ul>
            <p className='sub-heading'>OTHER OFFER DETAILS AND CONDITIONS:</p>
            <ul className="liparent" style={{"listStyleType":"lower-alpha"}}>
                <li>
                    <p> 
                    	The Offer is open to the Users On-Boarded as Buyers in the category of FMCG and HoReCa Products, apparel products and consumer electronics products in the Bangalore, Hyderabad and Delhi NCR regions. The applicable discounts on transactions will be applied via the Platform when the Eligible User places an order to purchase products from the sellers.
                    </p>
                </li>
                <li>
                    <p>
                      SOLV shall only process orders for products in serviceable pin codes listed out in Annexure-1 below. However it is clarified that the attached list of serviceable pin codes is tentative and subject to change from time to time without prior notice, depending on notification of containment zones on account of Covid-19 related restrictions imposed by governmental authorities, operational challenges, etc. The Users must seek the assistance of relevant SOLV sales officers to ascertain the up to date list of serviceable pin codes.    
                    </p>
                </li>
                <li>
                    <p>
                    If the User places a purchase order on the Platform, however the relevant seller does not accept such purchase order or the order is not processed, the User’s discount voucher shall not be considered as consumed. Further, if the User places an order for certain quantity of products on the Platform exceeding the threshold amount of invoice values mentioned above, however the order is only partially fulfilled by the seller as a result of which the invoice value falls below the threshold amounts, any redemption of discount voucher by the User shall be at the discretion and in the manner determined by SOLV.
                    </p>
                </li>
                <li>
                    <p>
                    	During the Offer Period, payment for orders by way of advance/pre-paid mode shall not be available on the Platform for availing the Offers. 
                    </p>
                </li>
                <li>
                    <p>
                    	The Eligible Users shall not be entitled to place reverse pick-up orders during the Offer Period. In case an Eligible User orders for products and avails the discount, such Eligible User shall not be entitled to return the goods. In the case the Eligible User wants reverse pick-up services, the discount availed by the User shall be forfeited and such amount will be adjusted in the manner determined by SOLV. 
                    </p>
                </li>
                <li>
                    <p>
                    	Any discount voucher once applied by Eligible Users for placing orders on the Platform (including in case of cancelled/RTO orders) shall be considered as consumed, unless otherwise stated in these Terms and Conditions specifically. 
                    </p>
                </li>
                <li>
                    <p>
                    	In the event any User is eligible for more than one Offer, such User shall be entitled to choose the Offer which the User wants to avail, subject to the following conditions:
                    </p>
                    <ol style={{"listStyleType":"upper-roman"}}>
                        <li>
                            <p>
                            	No two Offers can be clubbed for a single order; 
                            </p>
                        </li>
                        <li>
                            <p>
                            The relevant seller must receive the full invoice value from the Eligible User;
                            </p>
                        </li>
                        <li>
                            <p>
                               In case of COD payments, the Eligible User shall make payment of the invoice amount net of the applicable discount amount.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                    	The thresholds of minimum invoice amounts which are required to be fulfilled by Eligible Users for availing the Offers as set out in these Terms and Conditions shall be such amounts net off all applicable taxes.
                    </p>
                </li>
                <li>
                    <p>
                    	The participation in the Offer is entirely voluntary and it is understood that the participation by the Users shall be deemed to have been made on a voluntary basis.
                    </p>
                </li>
                <li>
                    <p>
                    	In all matters relating to the Offer, including the application of discount, the decisions of SOLV shall be final and binding. 
                    </p>
                </li>
                <li>
                    <p>
                    	Any person availing this Offer shall be deemed to have accepted these Terms. The Users shall continue to be bound to comply with the Primary Terms and Conditions and these Terms shall be in addition to and not in substitution /derogation to the Primary Terms and Conditions governing the usage of SOLV services. 
                    </p>
                </li>
                <li>
                    <p>
                    	SOLV reserves the right to modify at any time, all or any of the terms applicable to the Offer, including the terms contained herein, without assigning any reasons or without any prior intimation whatsoever. SOLV also reserves the right to discontinue or terminate the Offer without assigning any reasons or without any prior intimation whatsoever at any time during the Offer Period. 
                    </p>
                </li>
                <li>
                    <p>
                    	The Eligible Users shall not be entitled to assign the rights and benefits arising under this Offer to any other person. No requests for transfer or assignment of the benefits under the Offer shall be entertained.
                    </p>
                </li>
                <li>
                    <p>
                    	By participating in this Offer, the User expressly agrees that SOLV or any of its affiliates will not be liable or responsible for any loss or damage whatsoever that you may suffer, directly or indirectly, in connection with this Offer, including but not limited to that associated with his/her use or delivery or misuse of any promotional codes and/or benefit provided hereunder. In the event SOLV suffers any loss or damage arising out of or resulting from any inaccurate information provided by the User or breach of any applicable law or terms and conditions, the User shall be liable to indemnify SOLV for all the loss and damage incurred.
                    </p>
                </li>
                <li>
                    <p>
                    	Under no circumstances will the benefit being offered under this Offer be provided in cash by SOLV, i.e. there are no cash or other alternatives available in whole or in part, in relation to the benefits under this Offer.
                    </p>
                </li>
                <li>
                    <p>
                    	SOLV reserves the right to disqualify any merchant establishment or User from the benefits of the Offer and initiate appropriate legal action, if any fraudulent activity is identified as being carried out for the purpose of availing the benefits under the Offer. Further, SOLV reserves the right to deny honouring the Offer or any benefit thereof on the grounds of suspicion or abuse of the Offer by any User without providing the User any explanation thereof.
                    </p>
                </li>
                <li>
                    <p>
                    	Any queries or disputes from Users regarding issuance/denial of discount, can only be raised within 30 days from the date of expiry of Offer Period. 
                    </p>
                </li>
                <li>
                    <p>
                        All communication and notices about this Offer should be addressed to &nbsp;
                        <a className='mail-anchor' href="mailto:cs@solvezy.com">cs@solvezy.com</a>.
                    </p>
                </li>
                <li>
                    <p>
                        All disputes are subject to the exclusive jurisdiction of the competent courts/tribunals of Bangalore.
                    </p>
                </li>
            </ul>
            <p className='sub-heading text-center' id="annexture-section">ANNEXURE-1</p>
            <p className='sub-heading text-center'>SERVICEABLE PIN CODES</p>
            <p>
                <a className='mail-anchor' href={`/Serviceable-Pin-Codes-for-FMCG-HoReCa-SME.xlsx`} download>Pin codes serviceable for FMCG and HoReCa category for SME DAY offer</a>
            </p>
            <p>
                <a className='mail-anchor' href={`/Pin-code-servicable-for-Apparel.xlsx`} download>Pin codes serviceable for apparel category for SME DAY offer</a>
            </p>
            <p>
                <a className='mail-anchor' href={`/Serviceable-Pin-Codes-For-CE-and-Apparel.xlsx`} download>Pin codes serviceable for consumer electronics category for SME DAY offer</a>
            </p>

		</div>
	</>
    )
}

export default SmeDayOfferTnc
